<template>
  <div>
    <navbar
      :items="navbarItems"
      :fonticon="pageIcon"
      :navbartitle="pageTitle"
      :navbardescription="pageDescription"
    />
    <data-table
      :data-source="filtredUsers"
      :headers="headers"
      table-class="table-striped table-hover"
    >
      <template slot="actions">
        <router-link
          :to="{name: 'add_user'}"
          class="btn btn-outline-primary btn-icon"
          v-if="is_admin"
        >
          <i class="icofont icofont-plus" />
        </router-link>
      </template>
    </data-table>
  </div>
</template>
<script>
import {mapGetters} from 'vuex'
import DataTable from '../../components/dataTable/local.vue'
import {libelleComponentSort} from '../../components/dataTable/functions'
import navbar from '../../components/navbar.vue'
import {BOOLEAN_TYPE, COMPONENT_TYPE, DATE_TYPE, TEXT_TYPE} from '../../components/dataTable/dataType'
import { TYPE_SUPER_ADMIN } from '../../constants/app'
const typeCompteComponent = () => import('../../components/user/typeCompte.vue')
const Actions = () => import('../../components/user/actions.vue')
export default {
    components: { DataTable, navbar },
    data() {
      return {
        
        navbarItems: [
          {
              libelle: 'Utilisateurs'
          }
        ],
        pageIcon: 'la-user',
        pageTitle: 'Utilisateurs',
        pageDescription: 'Liste des utilisateurs',
      }
    },
    computed:{
        ...mapGetters({
            users: 'auth/users',
            typeComptes: 'auth/typeComptes',
            me: 'auth/me',
            is_super_admin: 'auth/is_super_admin',
            is_admin: 'auth/is_admin',
            is_veto: 'auth/is_veterinaire'
        }),
        filtredUsers(){
          if(this.is_super_admin) return this.users
          let typeCompte = this.typeComptes.find(element => element.code === TYPE_SUPER_ADMIN)
          return [...this.users].filter(item => item.uid !== this.me.uid && item.typeCompte !== typeCompte.uid)
        },
        canDoAction(){
          return this.is_admin || this.is_veto
        },
        headers(){
            return [
                {label: 'UID', name: 'uid', type: TEXT_TYPE, visible: this.is_super_admin},
                {label: 'Nom Complet', name: 'fullname', type: TEXT_TYPE, sortable: true},
                {label: 'Email', name: 'email', type: TEXT_TYPE, sortable: true},
                {label: 'Téléphone', name: 'telephone', type: TEXT_TYPE, sortable: true},
                {label: 'Type Compte', name: 'typeCompte', type: COMPONENT_TYPE, component: typeCompteComponent, sortable: true, function:(order, source) => libelleComponentSort(order, source, this.typeComptes, 'typeCompte')},
                {label: 'Actif', name: 'active', type: BOOLEAN_TYPE},
                {label: 'Derniere connexion', name: 'lastConnexion', type: DATE_TYPE, sortable: true, visible: this.is_super_admin},
                {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions,  visible: this.canDoAction}
            ]
        }
    }
}
</script>
<style lang="scss" scoped>

</style>